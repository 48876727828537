<template>
  <VFooter id="footer" class="bkg-default">
    <VSpacer />
    <NuxtLink :to="{ name: 'info-privacy' }" class="mr-5 footer-color link"
      >Privacy & terms</NuxtLink
    >
    <span class="footer-color footer-copyright">
      © aestell
    </span>
  </VFooter>
</template>
<script>
export default {
  name: 'Footer'
}
</script>
<style scoped>
#footer {
    position: relative;
    z-index: 0;
}
.footer-color {
  color: #939da3;
}
.link:hover {
  color: #6f7c82;
}
.footer-copyright {
  margin-right: 100px;
}
</style>
