import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6fb44412 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _341cb14a = () => interopDefault(import('../pages/download/index.vue' /* webpackChunkName: "pages/download/index" */))
const _dc815004 = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _d7441c74 = () => interopDefault(import('../pages/info/eula.vue' /* webpackChunkName: "pages/info/eula" */))
const _5ba00df7 = () => interopDefault(import('../pages/info/privacy.vue' /* webpackChunkName: "pages/info/privacy" */))
const _36ea8359 = () => interopDefault(import('../pages/info/refund.vue' /* webpackChunkName: "pages/info/refund" */))
const _7edb2636 = () => interopDefault(import('../pages/info/terms.vue' /* webpackChunkName: "pages/info/terms" */))
const _b1829e2e = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _bfa80c98 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _e1cd2c34 = () => interopDefault(import('../pages/auth/create/index.vue' /* webpackChunkName: "pages/auth/create/index" */))
const _6ea21392 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _4dbcde78 = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _4144e098 = () => interopDefault(import('../pages/dashboard/payments.vue' /* webpackChunkName: "pages/dashboard/payments" */))
const _f17d551a = () => interopDefault(import('../pages/payment/cancel.vue' /* webpackChunkName: "pages/payment/cancel" */))
const _10d43702 = () => interopDefault(import('../pages/payment/lifetime.vue' /* webpackChunkName: "pages/payment/lifetime" */))
const _759e23da = () => interopDefault(import('../pages/payment/success.vue' /* webpackChunkName: "pages/payment/success" */))
const _60d199c0 = () => interopDefault(import('../pages/support/contact.vue' /* webpackChunkName: "pages/support/contact" */))
const _36975a3e = () => interopDefault(import('../pages/support/info.vue' /* webpackChunkName: "pages/support/info" */))
const _50918aac = () => interopDefault(import('../pages/support/sales.vue' /* webpackChunkName: "pages/support/sales" */))
const _5ca2dcd2 = () => interopDefault(import('../pages/auth/create/success.vue' /* webpackChunkName: "pages/auth/create/success" */))
const _6a69d1af = () => interopDefault(import('../pages/auth/discord/callback.vue' /* webpackChunkName: "pages/auth/discord/callback" */))
const _3dd4ebc6 = () => interopDefault(import('../pages/auth/verify/_code.vue' /* webpackChunkName: "pages/auth/verify/_code" */))
const _2f40d1b2 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/dashboard",
    component: _6fb44412,
    name: "dashboard"
  }, {
    path: "/download",
    component: _341cb14a,
    name: "download"
  }, {
    path: "/info",
    component: _dc815004,
    name: "info",
    children: [{
      path: "eula",
      component: _d7441c74,
      name: "info-eula"
    }, {
      path: "privacy",
      component: _5ba00df7,
      name: "info-privacy"
    }, {
      path: "refund",
      component: _36ea8359,
      name: "info-refund"
    }, {
      path: "terms",
      component: _7edb2636,
      name: "info-terms"
    }]
  }, {
    path: "/payment",
    component: _b1829e2e,
    name: "payment"
  }, {
    path: "/admin/dashboard",
    component: _bfa80c98,
    name: "admin-dashboard"
  }, {
    path: "/auth/create",
    component: _e1cd2c34,
    name: "auth-create"
  }, {
    path: "/auth/login",
    component: _6ea21392,
    name: "auth-login"
  }, {
    path: "/auth/reset",
    component: _4dbcde78,
    name: "auth-reset"
  }, {
    path: "/dashboard/payments",
    component: _4144e098,
    name: "dashboard-payments"
  }, {
    path: "/payment/cancel",
    component: _f17d551a,
    name: "payment-cancel"
  }, {
    path: "/payment/lifetime",
    component: _10d43702,
    name: "payment-lifetime"
  }, {
    path: "/payment/success",
    component: _759e23da,
    name: "payment-success"
  }, {
    path: "/support/contact",
    component: _60d199c0,
    name: "support-contact"
  }, {
    path: "/support/info",
    component: _36975a3e,
    name: "support-info"
  }, {
    path: "/support/sales",
    component: _50918aac,
    name: "support-sales"
  }, {
    path: "/auth/create/success",
    component: _5ca2dcd2,
    name: "auth-create-success"
  }, {
    path: "/auth/discord/callback",
    component: _6a69d1af,
    name: "auth-discord-callback"
  }, {
    path: "/auth/verify/:code?",
    component: _3dd4ebc6,
    name: "auth-verify-code"
  }, {
    path: "/",
    component: _2f40d1b2,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
