exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var getUrl = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL___0___ = getUrl(require("./DYLOVASTUFF.woff"));
var ___CSS_LOADER_URL___1___ = getUrl(require("./DYLOVASTUFF.ttf"));
var ___CSS_LOADER_URL___2___ = getUrl(require("./Technique.woff2"));
var ___CSS_LOADER_URL___3___ = getUrl(require("./Technique.woff"));
var ___CSS_LOADER_URL___4___ = getUrl(require("./Technique.ttf"));
var ___CSS_LOADER_URL___5___ = getUrl(require("./Quicksand-Regular.woff2"));
var ___CSS_LOADER_URL___6___ = getUrl(require("./Quicksand-Regular.woff"));
var ___CSS_LOADER_URL___7___ = getUrl(require("./Quicksand-Regular.ttf"));
var ___CSS_LOADER_URL___8___ = getUrl(require("./Quicksand-Light.woff2"));
var ___CSS_LOADER_URL___9___ = getUrl(require("./Quicksand-Light.woff"));
var ___CSS_LOADER_URL___10___ = getUrl(require("./Quicksand-Light.ttf"));
var ___CSS_LOADER_URL___11___ = getUrl(require("./Quicksand-Medium.ttf"));
var ___CSS_LOADER_URL___12___ = getUrl(require("./Agane-55-roman.ttf"));
var ___CSS_LOADER_URL___13___ = getUrl(require("./Domine.woff2"));
var ___CSS_LOADER_URL___14___ = getUrl(require("./Domine.ttf"));
var ___CSS_LOADER_URL___15___ = getUrl(require("./EBGaramond-Regular.woff2"));
var ___CSS_LOADER_URL___16___ = getUrl(require("./EBGaramond-Regular.ttf"));
var ___CSS_LOADER_URL___17___ = getUrl(require("./Montserrat-Medium.woff2"));
var ___CSS_LOADER_URL___18___ = getUrl(require("./Montserrat-Medium.ttf"));
// Module
exports.push([module.id, "@font-face{font-family:DYLOVASTUFF;font-style:normal;font-weight:400;src:local(\"DYLOVASTUFF\"),url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff\");src:url(" + ___CSS_LOADER_URL___0___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL___1___ + ") format(\"truetype\")}@font-face{font-family:Technique Sans;src:url(" + ___CSS_LOADER_URL___2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___3___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL___4___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:Quicksand;src:url(" + ___CSS_LOADER_URL___5___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___6___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL___7___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:Quicksand;src:url(" + ___CSS_LOADER_URL___8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___9___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL___10___ + ") format(\"truetype\");font-weight:300;font-style:normal}@font-face{font-family:Quicksand Medium;src:url(" + ___CSS_LOADER_URL___11___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:Agane;src:url(" + ___CSS_LOADER_URL___12___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:Domine;src:url(" + ___CSS_LOADER_URL___13___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___14___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:EB Garamond;src:url(" + ___CSS_LOADER_URL___15___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___16___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:Montserrat Medium;src:url(" + ___CSS_LOADER_URL___17___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL___18___ + ") format(\"truetype\");font-weight:400;font-style:normal}", ""]);
