<template>
  <VLayout v-if="loading" :class="{ overlay: loading }" class="loading-overlay">
    <ul class="loader">
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </VLayout>
</template>
<script>
export default {
  name: 'Loader',
  data: () => ({
    loading: false
  }),
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    }
  }
}
</script>
<style scoped>
.loader {
  position: fixed;
  z-index: 3;
  left: 0;
  right: 0;
  top: 50%;
  margin: -30px auto 0;
  width: 85px;
  height: 60px;
  list-style: none;
}
.overlay {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: #fff;
  opacity: 0.7;
  justify-content: center;
  align-items: center;
  z-index: 2147483638;
}

li {
  background-color: #486bb2;
  width: 10px;
  height: 10px;
  float: right;
  margin-right: 5px;
  box-shadow: 0px 100px 20px rgba(0, 0, 0, 0.2);
}
li:first-child {
  -webkit-animation: loadbars 0.6s cubic-bezier(0.645, 0.045, 0.355, 1) infinite
    0s;
}
li:nth-child(2) {
  -webkit-animation: loadbars 0.6s ease-in-out infinite -0.2s;
}
li:nth-child(3) {
  -webkit-animation: loadbars 0.6s ease-in-out infinite -0.4s;
}
</style>
