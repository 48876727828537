import createCheckoutSession from '~/gql/payments/createCheckoutSession.gql'
import createCheckoutSessionPwd from '~/gql/payments/createCheckoutSessionPwd.gql'
import getMySubscription from '~/gql/payments/getMySubscription.gql'
import cancelOrResumeMySubscription from '~/gql/payments/cancelOrResumeMySubscription.gql'
import changeMySubscription from '~/gql/payments/changeMySubscription.gql'
import getSku from '~/gql/payments/getSku.gql'
import getPlans from '~/gql/payments/getPlans.gql'

export default {
  createCheckoutSession,
  createCheckoutSessionPwd,
  getMySubscription,
  changeMySubscription,
  getSku,
  getPlans,
  cancelOrResumeMySubscription
}
