<template>
  <section v-if="!acceptedCookies" class="cookie-wrapper">
    <VCard class="cookies bkg-default px-12 py-6">
      <img :src="require('~/assets/img/cookies.svg')" class="mb-8"/> 
      <p class="mb-5">This website uses cookies to ensure you get the best experience on our website.</p><br>
      <p>By using our site, you consent to cookies. <a href="https://aestell.com/info/privacy">Learn more</a></p>
      <VBtn 
        @click="accept" 
        class="mt-8 px-12"
        color="#4D95E8"
        ripple
      >OK</VBtn>
    </VCard>
  </section>
</template>

<script>
import Vue from 'vue'

export default {
  data () {
    return {
      acceptedCookies: true
    }
  },

  methods: {
    accept ( ) {
      window.localStorage.setItem( 'acceptedCookies', true )
      this.acceptedCookies = true
    }
  },

  mounted () {
    Vue.set(this, 'acceptedCookies', window.localStorage.acceptedCookies === "true")
  }
}
</script>

<style lang="scss">
section.cookie-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(#222B41, 0.8);
  z-index: 1001;

  button {
      height: 46px !important;
  }

  &, & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & > div {
    width: 35rem;
    max-width: 60vw;

    p {
      width: 100%;
      margin-bottom: 0;
    }

    button {
      color: white !important;
    }

    &, button {
      border-radius: 10px !important;
    }
  }
}
</style>