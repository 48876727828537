<template>
  <VNavigationDrawer v-model="drawer" fixed temporary>
    <VList>
      <VListItem exact :to="{ name: 'index' }" class="drawer-link">
        <VListItemContent>
          <VListItemTitle>Home</VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListGroup class="drawer-link">
        <template v-slot:activator>
          <VListItemTitle>Documentation</VListItemTitle>
        </template>
        <VListItem
          v-for="(item, i) in docs"
          :key="i"
          exact
          :to="item.url"
          class="drawer-link"
        >
          <VListItemContent>
            <VListItemTitle v-text="item.title"></VListItemTitle>
          </VListItemContent>
        </VListItem>
      </VListGroup>
      <VListItem exact :to="{ name: 'support-info' }" class="drawer-link">
        <VListItemContent>
          <VListItemTitle>Support</VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListItem exact :to="{ name: 'auth-login' }" class="drawer-link">
        <VListItemContent>
          <VListItemTitle>Login</VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VListItem exact :to="{ name: 'auth-create' }" class="drawer-link">
        <VListItemContent>
          <VListItemTitle>Sign up</VListItemTitle>
        </VListItemContent>
      </VListItem>
    </VList>
  </VNavigationDrawer>
</template>

<script>
export default {
  name: 'Drawer',
  data() {
    return {
      drawer: false,
      group: null,
      docs: [
        {
          title: 'Terms of service',
          url: { name: 'info-terms' }
        },
        {
          title: 'Privacy policy',
          url: { name: 'info-privacy' }
        },
        {
          title: 'Eula',
          url: { name: 'info-eula' }
        },
        {
          title: 'Refund policy',
          url: { name: 'info-refund' }
        }
      ]
    }
  },
  watch: {
    group() {
      this.drawer = false
    }
  }
}
</script>

<style lang="scss">
.drawer-link {
  transition: all 0.2s ease;

  &:hover {
    color: #5b3cc4;
    filter: opacity(0.5) drop-shadow(0 0 0 blue);
  }

  &.v-list-item--active {
    color: #5b3cc4;
    filter: opacity(0.5) drop-shadow(0 0 0 blue);
  }

  &.v-list-group--active .v-list-item--active {
    color: #5b3cc4;
  }
}
</style>
