import Vue from 'vue'
import { onError } from 'apollo-link-error'

export default function(ctx) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      if (process.client) {
        Vue.swal(networkError)
      }
    }
    if (graphQLErrors) {
      graphQLErrors.map(({ message, code }) => {
        if (code !== 'notFound') {
          if (process.client) {
            Vue.swal({
              position: 'center',
              type: 'error',
              title: message,
              showConfirmButton: true,
              timer: 1850
            })
          }
        }
      })
    }
  })
  return {
    link: errorLink,
    // required
    httpEndpoint: process.env.GQL_URL || 'http://api.aestell.com/graphql',

    httpLinkOptions: {
      credentials: 'same-origin'
    },
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    wsEndpoint: null, // optional
    // LocalStorage token
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false // Optional
  }
}
