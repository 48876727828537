import createLicense from '~/gql/admin/createLicense.gql'
import updateLicense from '~/gql/admin/updateLicense.gql'
import deleteLicense from '~/gql/admin/deleteLicense.gql'
import listLicenses from '~/gql/admin/listLicenses.gql'
import createNewBotVersion from '~/gql/admin/createNewBotVersion.gql'
import getLatestBotVersion from '~/gql/admin/getLatestBotVersion.gql'
import listCrawlCategories from '~/gql/admin/listCrawlCategories.gql'
import uploadBotCategories from '~/gql/admin/uploadBotCategories.gql'
import getSettings from '~/gql/admin/getSettings.gql'
import updateSettings from '~/gql/admin/updateSettings.gql'

export default {
  createLicense,
  deleteLicense,
  updateLicense,
  listLicenses,
  listCrawlCategories,
  uploadBotCategories,
  getLatestBotVersion,
  getSettings,
  updateSettings,
  createNewBotVersion
}
