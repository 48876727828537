<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="label || $attrs.name"
    :vid="vid"
    mode="eager"
    slim
  >
    <VTextField
      v-model="innerValue"
      v-bind="$attrs"
      :label="label"
      :error-messages="errors"
      :hide-details="errors.length === 0 && hideDetails"
      :type="type"
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'TextFieldWithValidation',
  components: { ValidationProvider },
  props: {
    rules: {
      type: [String, Object],
      default: null,
      required: true
    },
    label: {
      type: String,
      default: null,
      required: false
    },
    vid: String,
    value: {
      type: String,
      default: null,
      required: false
    },
    type: {
      type: String,
      default: null,
      required: false
    },
    hideDetails: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      innerValue: ''
    }
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
    value(newVal) {
      this.innerValue = newVal
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
