import register from './register'
import auth from './auth'
import user from './user'
import admin from './admin'
import bot from './bot'
import payments from './payments'

export default {
  register,
  payments,
  auth,
  bot,
  admin,
  user
}
