import graphql from '~/gql'

export const state = () => {
  return {
    user: null,
    authorized: false
  }
}
export const mutations = {
  setCurrentUser(state, user) {
    state.user = user
  }
}

export const getters = {
  isAuthorized(state) {
    return state.user !== null
  }
}
export const actions = {
  async getCurrentUser(ctx) {
    const client = this.app.apolloProvider.defaultClient
    const res = await client.query({
      query: graphql.user.getCurrentUser
    })
    if (res.data.getCurrentUser) {
      ctx.commit('setCurrentUser', res.data.getCurrentUser)
    }
  },
  async logout(ctx) {
    await this.app.$apolloHelpers.onLogout()
    ctx.commit('setCurrentUser', null)
  },
  async login(ctx, { token, user }) {
    await this.app.$apolloHelpers.onLogin(token)
    ctx.commit('setCurrentUser', user)
  }
}
