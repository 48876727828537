<template>
  <VAppBar elevate-on-scroll app class="bkg-default toolbar">
    <VContainer>
      <VLayout row>
        <VToolbarItems class="hidden-xs-only flex-grow-1 justify-space-around">
          <NLink class="mx-4 toolbar-link text-center" :to="{ name: 'index' }"
            ><img
              src="~assets/img/round-home.svg"
              class="pr-1 toolbar-link-icon home-icon"
            /><span class="toolbar-text">Home</span></NLink
          >
          <VMenu offset-y open-on-hover bottom>
            <template v-slot:activator="{ on }">
              <VBtn
                nuxt-link
                class="mx-4 toolbar-link text-center"
                text
                color="#445767"
                :to="{ name: 'info-terms' }"
                v-on="on"
              >
                <span class="toolbar-text">Documentation</span>
              </VBtn>
            </template>
            <v-list class="pa-4">
              <v-list-item-group>
                <v-list-item :to="{ name: 'info-terms' }">
                  <v-list-item-title class="text-uppercase"
                    >Terms of Service</v-list-item-title
                  >
                </v-list-item>
                <v-list-item :to="{ name: 'info-privacy' }">
                  <v-list-item-title class="text-uppercase"
                    >Privacy Policy</v-list-item-title
                  >
                </v-list-item>
                <v-list-item :to="{ name: 'info-eula' }">
                  <v-list-item-title class="text-uppercase"
                    >EULA</v-list-item-title
                  >
                </v-list-item>
                <v-list-item :to="{ name: 'info-refund' }">
                  <v-list-item-title class="text-uppercase"
                    >Refund Policy</v-list-item-title
                  >
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </VMenu>
          <NLink
            class="mx-4 toolbar-link text-center"
            :to="{ name: 'support-info' }"
            ><span class="toolbar-text">Support</span></NLink
          >
          <VBtn
            v-show="isAuthorized"
            nuxt-link
            class="mx-4 toolbar-link text-center"
            text
            color="#445767"
            to="/dashboard"
            ><span class="toolbar-text">Dashboard</span></VBtn
          >
          <VBtn
            v-show="isAuthorized"
            nuxt-link
            class="mx-4 toolbar-link text-center"
            text
            color="#445767"
            @click="onLogout"
            ><img
              src="~assets/img/round-meeting_room.svg"
              class="pr-1 toolbar-link-icon"
            /><span class="toolbar-text">Logout</span></VBtn
          >
          <NLink
            v-show="!isAuthorized"
            class="mx-4 toolbar-link text-center"
            :to="{ name: 'auth-login' }"
            ><img
              src="~assets/img/round-meeting_room.svg"
              class="pr-1 toolbar-link-icon"
            /><span class="toolbar-text">Login</span></NLink
          >
          <NLink
            v-show="!isAuthorized"
            class="mx-4 toolbar-link text-center"
            :to="{ name: 'auth-create' }"
            ><img
              src="~assets/img/baseline-face.svg"
              class="pr-1 toolbar-link-icon"
            /><span class="text-no-wrap toolbar-text">Sign up</span></NLink
          >
        </VToolbarItems>
        <VLayout class="hidden-sm-and-up">
          <VAppBarNavIcon @click.stop="$emit('toggle-drawer')"></VAppBarNavIcon>
          <NuxtLink
            :to="{ name: 'index' }"
            class="ml-12 logo-text logo-text-small"
          >
            aestell
          </NuxtLink>
        </VLayout>
      </VLayout>
    </VContainer>
  </VAppBar>
</template>
<script>
export default {
  name: 'Toolbar',
  computed: {
    isAuthPage() {
      return this.$route.name.startsWith('auth')
    },
    isAuthorized() {
      return this.$store.getters['auth/isAuthorized']
    },
    user() {
      return this.$store.state.auth.user || {}
    }
  },
  methods: {
    async onLogout() {
      await this.$router.push('/')
      await this.$store.dispatch('auth/logout')
    }
  }
}
</script>
<style scoped>
.toolbar-link {
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: normal;
  text-transform: capitalize;
  text-decoration: none;
  color: #445767;
  position: relative;
}
.toolbar-text {
  position: relative;
  display: flex;
}
.toolbar-link:before {
  background: unset;
}
.toolbar-link,
.toolbar-text:after {
  transition: all 0.2s ease;
}
.toolbar-link:hover {
  color: #5b3cc4 !important;
  filter: opacity(0.5) drop-shadow(0 0 0 blue);
}
.toolbar-link-icon {
  vertical-align: middle;
  margin-top: -3px;
}
.toolbar-link.nuxt-link-exact-active {
  color: #5b3cc4;
  filter: opacity(0.5) drop-shadow(0 0 0 blue);
}
.toolbar-link .toolbar-text:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  height: 3px;
  border-radius: 0 0 2px 2px;
  background: #5b3cc4;
  top: -1px;
}
.toolbar-link.nuxt-link-exact-active .toolbar-text:after,
.toolbar-link:hover .toolbar-text:after {
  width: 60% !important;
  color: #5b3cc4;
}
.logo-hide {
  opacity: 0;
}
.home-icon {
  width: 35px;
}
</style>
