<template>
  <Layout>
    <VContainer grid-list-xl text-xs-center fluid>
      <VLayout>
        <VFlex v-if="error.statusCode === 404" text-center>
          <h1 class="logo-text">404</h1>
          <img src="~assets/img/404.svg" width="656px" />
        </VFlex>
        <VFlex v-else
          ><h1 class="logo-text">oops, something went wrong</h1></VFlex
        >
      </VLayout>
      <VLayout>
        <VFlex :class="{ 'text-center': $vuetify.breakpoint.xs }">
          <VBtn to="/" min-width="170px" color="#F57D00" dark>
            <font-awesome-icon
              class="mr-4"
              :icon="['fa', 'arrow-left']"
              :style="{ color: 'white' }"
            ></font-awesome-icon
            >Back</VBtn
          >
        </VFlex>
      </VLayout>
    </VContainer>
  </Layout>
</template>

<script>
import Layout from '../components/layout/Layout'
export default {
  components: { Layout },
  props: {
    error: {
      type: Object,
      required: true
    }
  }
}
</script>
