<template>
  <VApp>
    <div class="d-flex header-wrapper" v-if="!noHeader">
      <slot name="logo"
        ><Logo class="logo-animate ml-5 hidden-sm-and-down"></Logo
      ></slot>
      <slot name="toolbar"
        ><Toolbar @toggle-drawer="handleToggleDrawer"></Toolbar
      ></slot>
    </div>
    <slot name="top"></slot>
    <VContent class="bkg-default" id="content">
      <slot></slot>
      <VLayout column bottom right fixed class="fabs">
        <ContactFab class="fabs__fab" />
        <VSpeedDial direction="left" open-on-hover class="fabs__fab">
          <template v-slot:activator>
            <VCard>
              <VLayout
                align-center
                justify-center
                row
                fill-height
                class="shadow"
              >
                <img class="logo" src="~assets/img/logo.svg" />
              </VLayout>
            </VCard>
          </template>
          <VCard style="width: 150px; height: 70px">
            <VCardText>
              <p>
                Some Text Here
                <nuxt-link :to="{ name: 'info-overview' }">Privacy</nuxt-link>
              </p>
            </VCardText>
          </VCard>
        </VSpeedDial>
      </VLayout>
    </VContent>
    <slot name="footer"><Footer></Footer></slot>
    <Drawer ref="drawer" />
    <Cookies />
  </VApp>
</template>

<script>
import Toolbar from './Toolbar'
import Footer from './Footer'
import Drawer from './Drawer'
import Logo from '~/components/Logo'
import ContactFab from '~/components/fabs/ContactFab'
import Cookies from '~/components/Cookies'

export default {
  name: 'Layout',
  components: { Logo, Toolbar, Footer, ContactFab, Drawer, Cookies },
  props: {
    noHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    handleToggleDrawer() {
      this.$refs.drawer.drawer = !this.$refs.drawer.drawer
    }
  }
}
</script>
<style lang="scss" scoped>
.logo-animate {
  z-index: 99;
  width: 182px;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-box-shadow: 0px 7px 23px -6px rgba(135, 135, 135, 0.7);
  -moz-box-shadow: 0px 7px 23px -6px rgba(135, 135, 135, 0.7);
  box-shadow: 0px 7px 23px -6px rgba(135, 135, 135, 0.7);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding: 12px;
}

.header-wrapper {
  position: fixed;
  z-index: 2;
  width: 100%;
  pointer-events: none;
  & > * {
    pointer-events: all;
  }
}

.fabs {
  z-index: 1000;
  position: fixed;
  bottom: 16px;
  right: 16px;

  &__fab {
    margin: 6px;
  }
}

#content.bkg-default {
  position: static;
}
</style>

<style lang="scss">
html {
  overflow-y: auto !important;
}

div.d-flex.header-wrapper > header.bkg-default {
  position: static;
}
</style>