import getCurrentUser from '~/gql/user/getCurrentUser.gql'
import getMyLicense from '~/gql/user/getMyLicense.gql'
import unbindMyLicenseFromDevice from '~/gql/user/unbindMyLicenseFromDevice.gql'
import unbindMyLicenseFromUser from '~/gql/user/unbindMyLicenseFromUser.gql'
import activateLicense from '~/gql/user/activateLicense.gql'

export default {
  getCurrentUser,
  getMyLicense,
  activateLicense,
  unbindMyLicenseFromDevice,
  unbindMyLicenseFromUser
}
