<template>
  <div>
    <img src="~assets/img/top-logo.svg" class="logo-left mx-auto" />
    <div v-if="$store.state.showLogo" class="mx-auto">
      <h4 class="text-center">Launched - 2019</h4>
      <h4 class="text-center">All Rights Reserved</h4>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Logo'
}
</script>
<style lang="scss" scoped>
img.logo-left {
  width: 108px;
  height: auto;
}

h4 {
  font-family: 'Quicksand', Sans-Serif, serif;
  font-weight: normal;
  font-size: 16px;

  &:first-child {
    margin-top: 12px
  }
}
</style>