import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

    import  { faCheckSquare as fortawesomefreeregularsvgicons_faCheckSquare } from '@fortawesome/free-regular-svg-icons'
    library.add(fortawesomefreeregularsvgicons_faCheckSquare)

    import  { faPlusCircle as fortawesomefreesolidsvgicons_faPlusCircle } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faPlusCircle)

    import  { faEdit as fortawesomefreesolidsvgicons_faEdit } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faEdit)

    import  { faArrowLeft as fortawesomefreesolidsvgicons_faArrowLeft } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faArrowLeft)

    import  { faTrashAlt as fortawesomefreesolidsvgicons_faTrashAlt } from '@fortawesome/free-solid-svg-icons'
    library.add(fortawesomefreesolidsvgicons_faTrashAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
