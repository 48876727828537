<template>
  <VCard class="pa-5">
    <VCardText>
      <ValidationObserver ref="form" slim>
        <VForm>
          <VLayout row>
            <TextFieldWithValidation
              v-model="email"
              rules="required|email"
              label="Enter your email"
              name="email"
              type="email"
              autocomplete="email"
              @keyup.enter="submit"
            />
          </VLayout>
          <VLayout row>
            <TextFieldWithValidation
              v-model="subject"
              rules="required"
              label="What is your question about?"
              name="subject"
              @keyup.enter="submit"
            />
          </VLayout>
          <VLayout row>
            <TextFieldWithValidation
              v-model="message"
              rules="required"
              label="Tell us more"
              name="message"
              @keyup.enter="submit"
            />
          </VLayout>
        </VForm>
      </ValidationObserver>
    </VCardText>
    <VCardActions>
      <VLayout row wrap justify-space-between>
        <VBtn
          :disabled="submitting"
          :loading="submitting"
          ripple
          width="158"
          height="42"
          class="ma-2"
          @click="fileUpload"
        >
          <img src="~assets/img/upload-rocket.svg" />
          Upload A File
        </VBtn>
        <VBtn
          :disabled="submitting"
          :loading="submitting"
          color="#918BFE"
          ripple
          dark
          height="42"
          class="ma-2"
          @click="send"
        >
          <img src="~assets/img/baseline-send.svg" />
        </VBtn>
      </VLayout>
    </VCardActions>
  </VCard>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import TextFieldWithValidation from '~/components/TextFieldWithValidation'

export default {
  name: 'ContactForm',
  components: {
    TextFieldWithValidation,
    ValidationObserver
  },
  data() {
    return {
      email: '',
      subject: '',
      message: '',
      submitting: false
    }
  },
  methods: {
    fileUpload() {},
    async send() {
      try {
        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.$nuxt.$loading.start()
          try {
            console.log('send')
          } catch (error) {
            console.log('Login error:', error)
          } finally {
            this.$nuxt.$loading.finish()
          }
        }
      } catch (error) {
        console.log('Validation error:', error)
      }
    }
  }
}
</script>
