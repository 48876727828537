<template>
  <div class="contactFab">
    <v-fade-transition hide-on-leave>
      <VBtn
        v-show="isFormOpen"
        fab
        depressed
        color="#6265ae"
        :height="sizeBtn"
        :width="sizeBtn"
        class="teardrop"
        @click="toggleForm"
      >
        <img
          class="contactFab__icon teardrop__icon"
          src="~assets/img/icons/close.svg"
          alt="Close"
        />
      </VBtn>
    </v-fade-transition>

    <v-fade-transition hide-on-leave>
      <VBtn
        v-show="!isFormOpen"
        fab
        color="#f16988"
        :height="sizeBtn"
        :width="sizeBtn"
        style="box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;"
        @click="toggleForm"
      >
        <img
          class="contactFab__icon"
          src="~assets/img/icons/message.svg"
          alt="Message"
        />
      </VBtn>
    </v-fade-transition>

    <v-fade-transition>
      <ContactForm v-show="isFormOpen" class="contactFab__form" />
    </v-fade-transition>
  </div>
</template>

<script>
import ContactForm from '~/components/ContactForm'

export default {
  name: 'ContactFab',
  components: { ContactForm },
  data() {
    return {
      isFormOpen: false,
      sizeBtn: '55'
    }
  },
  methods: {
    toggleForm() {
      this.isFormOpen = !this.isFormOpen
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/variables';

.teardrop {
  align-items: center;
  background: #6265ae;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  border-radius: 0 50% 50% 50%;
  display: flex;
  justify-content: center;
  transform: rotate(45deg);

  &__icon {
    transform: rotate(45deg);
  }
}

.contactFab {
  position: relative;

  &__form {
    bottom: 90px;
    max-width: 77vw;
    position: absolute;
    right: 15px;
    width: 378px;

    @media (min-width: #{map-get($grid-breakpoints, 'sm')}) {
      max-width: none;
    }
  }

  &__icon {
    height: 20px;
    width: 20px;
  }
}
</style>
